<template>
  <v-app>
    <v-content ref="authContainer" class="primary">
      <v-container fill-height>
        <v-row align="center" justify="center" no-gutters>
          <v-card
            class="elevation-3 pa-3 br-5 pa-lg-4"
            shaped
            max-width="500"
            width="100%"
          >
            <v-card-title primary-title>
              <v-layout column justify-center align-center>
                <v-layout justify-center align-center class="mb-2">
                  <v-img
                    src="/logo.svg"
                    width="150"
                    contain
                    class="mx-auto"
                  />
                </v-layout>
              </v-layout>
            </v-card-title>
            <router-view />
          </v-card>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: 'Auth'
}
</script>
